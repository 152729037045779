import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

import classes from "./blog-post.module.scss"

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "Do MMMM, YYYY")
        category
        featured_image {
          publicURL
        }
      }
      html
    }
  }
`

const Blog = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  // const siteTitle = data.site.siteMetadata.title
  // const { previous, next } = pageContext

  return (
    <Layout>
      {/* <Head title={data.markdownRemark.frontmatter.title} /> */}
      <SEO
        title={post.frontmatter.title}
        image={post.frontmatter.featured_image.publicURL}
        description={post.frontmatter.description || post.excerpt}
      />
      <h1>{data.markdownRemark.frontmatter.title}</h1>
      <p className={classes.categoryHolder}>
        {data.markdownRemark.frontmatter.category.map((c, i) => (
          <span className={classes.category} key={i}>
            #{c}
          </span>
        ))}
      </p>
      <p>{data.markdownRemark.frontmatter.date}</p>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
    </Layout>
  )
}

export default Blog
